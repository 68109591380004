<template lang="pug">
div
  .costs-list-title(
    @click="setActive('Corporate rental')"
  )
    span
      i.fas.fa-chevron-right.costs-list-chevron
      span.costs-list-title-text {{ $t("financeForms.corporateRental") }}
    span.costs-list-price
     span.big {{ costPrice }}
     | &nbsp;&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

  .costs-list-content
    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="true"
    )
    DistanceChoiser(
      :fixedCostsBy="fixedCostsByMonth"
      :showPices="true"
    )

</template>

<script>
import CostsMixin from '@/mixins/CostsMixin'

export default {
  mixins: [ CostsMixin ],
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
    DistanceChoiser: () => import('./../costs-parts/distance'),
  },
  computed: {
    costPrice() {
      return this.getFormCostPrice('Corporate rental')
    },
  },
  methods: {
    setActive(value) {
      this.$emit('click', value)
    }
  }
}
</script>